import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path:      '/',
        name:      'login',
        component: () => import('../views/login/Login.vue'),
    },
    {
        path:      '/overview',
        name:      'overview',
        component: () => import('../views/transportRide/overview/Overview.vue'),
    },
    {
        path:      '/ride/:rideId',
        name:      'loading',
        redirect:  { name: 'loading_view' },
        component: () => import('../views/transportRide/overview/ride/item/Loading.vue'),
        meta:      {
            breadCrumbs: params => `RIT ${params.rideId}`,
        },
        children:  [
            {
                path:      '/',
                name:      'loading_view',
                component: () => import('../views/transportRide/overview/ride/item/LoadingView.vue'),
            },
            {
                path:      'options_view/:itemId',
                name:      'options',
                redirect:  { name: 'options_view' },
                component: () => import('../views/transportRide/overview/ride/item/options/Options.vue'),
                meta:      {
                    breadCrumbs: params => `${params.item.packingSlip ? params.item.packingSlip.order : params.item.order.id}`,
                },
                children:  [
                    {
                        path:      '/',
                        name:      'options_view',
                        component: () => import('../views/transportRide/overview/ride/item/options/OptionsView.vue'),
                    },
                    {
                        path:      'unload_view/',
                        name:      'unload_view',
                        component: () => import('../views/transportRide/overview/ride/item/UnloadView.vue'),
                        meta:      {
                            breadCrumbs: `LOSSEN`,
                        },
                    },
                ],
            },
            {
                path:      'options_view/:itemId',
                name:      'purchase_options',
                redirect:  { name: 'item_options_view' },
                component: () => import('../views/transportRide/overview/ride/item/options/Options.vue'),
                meta:      {
                    breadCrumbs: params => `${params.item.purchaseOrder[0]}`,
                },
                children:  [
                    {
                        path:      '/',
                        name:      'item_options_view',
                        component: () => import('../views/transportRide/overview/ride/item/options/ItemOptionsView.vue'),
                    },
                    {
                        path:      'item_loading_view/',
                        name:      'item_loading_view',
                        component: () => import('../views/transportRide/overview/ride/item/ItemLoadingView.vue'),
                        meta:      'LADEN'
                    }

                ]
            },
        ],
    },
]

const router = new VueRouter({
    routes,
})

export default router
