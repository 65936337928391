import Vue from "vue";

Vue.filter("zeroPad", (value) => {
    const formattedValue = value.toString();
    switch (formattedValue.length) {
        case 4:  return '0'    + formattedValue;
        case 3:  return '00'   + formattedValue;
        case 2:  return '000'  + formattedValue;
        case 1:  return '0000' + formattedValue;
        default: return          formattedValue;
    }
});

Vue.filter('capitalize', (value) => {
    const  formattedValue = value.toString();
    return formattedValue !== ''
        ? formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1)
        : formattedValue
    ;
})

Vue.filter('amount', (value) => {
    return new Intl.NumberFormat('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
})

Vue.filter('quantity', (value) => {
    return new Intl.NumberFormat('nl-NL', { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(value);
})
