import                 './filters';
import                 './registerServiceWorker'
import                 './scss/styles.scss'
import App        from './App.vue';
import i18next    from 'i18next';
import Icons      from 'uikit/dist/js/uikit-icons';
import resources  from '../locales';
import router     from './router'
import UIkit      from 'uikit';
import Vue        from 'vue';
import VueI18Next from '@panter/vue-i18next';

UIkit.use(Icons);

Vue.config.productionTip = false
i18next.init({
    fallbackLng: 'nl',
    lng:         'nl',
    defaultNS:   'main',
    resources,
}).then(() => {
    Vue.use(VueI18Next);

    new Vue({
        router,
        render: h => h(App),
        i18n:   new VueI18Next(i18next),
    }).$mount('#app');
});
